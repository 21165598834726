<template>
  <div class="main">
    <div class="maximal">
      <div class="picture">
        <img src="../assets/img/lgogintag.jpg" class="retrievePassword">
        <span>找回密码</span>
      </div>
      <h1 class="Tips">找回密码，请联系管理员</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RetrievePassword'
}
</script>

<style lang="scss" scoped>
.maximal{
  width: 800px;
  height: 240px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 15px;
  border: 1px solid #bedbfd;
  text-align:center;
  margin-top:40px;
}

.Tips{
  margin-top:70px;
}

.picture{
  float: left;
  margin-top: 10px;
  .retrievePassword{
    width: 180px;
  }
  span{
    // float: left;
    margin-left:-140px;
    // z-index: 9;
    color: #FFFFFF;
    font-size: 18px;
  }
}
</style>
